<template>
  <komponen-penyakit-create
    headerText="diagnosis"
    :komponenMdl="diagnosisMdl"
    jenisKomponen="diagnosis"
  >
  <b-field
    label="Grup"
    custom-class="is-small notrequired"
    :type="{'is-danger': errorMap.grup}"
    :message="errorMap.grup"
  >
    <generic-select
      v-model="komponen.grup"
      apiPath="/penyakit/diagnosis/grup/"
      @input="validateInput('grup')"
    ></generic-select>
  </b-field>
  <b-field
    label="Level Kompetensi"
    custom-class="is-small"
    :type="{'is-danger': errorMap.skdi}"
    :message="errorMap.skdi"
  >
    <generic-select
      v-model="komponen.skdi"
      apiPath="/penyakit/skdi/"
      @input="validateInput('skdi')"
    ></generic-select>
  </b-field>
  </komponen-penyakit-create>
</template>


<script>
import onlineCheckMixin from "@/apps/core/mixins/onlineCheckMixin";
import Diagnosis from "../models/subDeptDiagnosis.js";


export default {
  name: "DiagnosisCreate",
  props: ["headerText", "jenisKomponen"],
  components: {
    KomponenPenyakitCreate: () =>
      import("@/apps/penyakit/views/KomponenPenyakitCreate.vue"),
    GenericSelect: () => import("@/apps/core/components/GenericSelect.vue"),
  },
  data() {
    this.diagnosisMdl = new Diagnosis("komponen");
    let obv = this.diagnosisMdl.getObservables();
    obv.sub_departemen_id = this.$route.params.id;
    return obv;
  },
  methods: {
    validateInput(field) {
      this.diagnosisMdl.validate(field);
    }
  },
  mixins: [onlineCheckMixin] // tidak perlu fetch data karena hanya create.
};
</script>

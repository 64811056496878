import KomponenBase from "./komponenBase.js"

const modelName = "komponen";
const modelPath = null;

class Diagnosis extends KomponenBase {
  constructor(overrideName) {
    let model = { grup: null, skdi: null };
    let requiredFields = ["skdi"];
    let ovrName = overrideName ? overrideName : modelName;
    super(ovrName, model, requiredFields, ["grup"], modelPath);
  }

  getPayload() {
    let data = super.getPayload();
    data.sub_departemen = this.observables.sub_departemen_id;
    return data;
  }
}

export default Diagnosis;